header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  height: 4.375rem;
  .logo {
    width: 30rem;
    height: auto;
    margin-left: 1rem;;
  }
  ul {
    display: flex;
    column-gap: 1rem;
    margin-right: 1rem;
    li {
      list-style: none;
      a {
        text-decoration: none;
        color: rgb(0, 0, 0);
      }
    }
  }
}
