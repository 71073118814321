#contact {
  background-color: rgba(255, 255, 255, 0.8);
  background-image: url('../../../assets/images/bg_004.png');
  background-blend-mode: lighten;
  background-size: cover;
  display: flex;
  align-items: center;
  .title {
    width: 50%;
    font-size: 2rem;
    text-align: center;
  }
  .details {
    width: 50%;
    line-height: 1.75;
    .form_row {
      display: flex;
      column-gap: 0.5rem;
      align-items: center;
      font-size: 1rem;
      width: 100%;
      margin-bottom: 0.25rem;
      label {
        width: 9rem;
        text-align: right;
      }
      input, textarea {
        width: calc(100% - 9rem);
        border: 1px solid darkgrey;
        border-radius: 4px;
        padding: 2px;
      }
      button {
        width: calc(100% - 9rem - 0.5rem + 1px);
        margin-left: auto;
        background-color: darkcyan;
        border-radius: 4px;
        padding: 2px;
        color: aliceblue;
      }
    }
    .list_link {
      display: flex;
      justify-content: end;
      align-items:center;
      column-gap: 1rem;
      a {
        text-decoration: none;
      }
      .list_link_x {
        font-size: 1.25rem;
        width: 2rem;
        height: 2rem;
        background-color: black;
        border-radius: 50%;
        text-align: center;
        svg {
          fill: white;

        }
      }
      .list_link_cw {
        width: 2rem;
        height: 2rem;
        background-color: rgb(52, 52, 148);
        border-radius: 10%;
        font-weight: 500;
        text-align: center;
        a {
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  #contact {
    background-image: url('../../../assets/images/bg_004_sp.png');;
    background-size: cover;
    flex-direction: column;
    .title {
      width: 100%;
      margin-top: 4rem;
      font-size: 1.5rem;
    }
    .details {
      width: 100%;
      margin-top: 2rem;
      line-height: 1.75;
      .form_row {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        width: 100%;
        margin-bottom: 0.25rem;
        label {
          width: 100%;
          text-align: left;
        }
        input, textarea {
          width: 100%;
          border: 1px solid darkgrey;
          border-radius: 4px;
          padding: 2px;
        }
        button {
          width: 100%;
          margin-left: auto;
          background-color: darkcyan;
          border-radius: 4px;
          padding: 2px;
          color: aliceblue;
        }
      }
    }
  }
}