#profile {
  background-color: rgba(255, 255, 255, 0.75);
  background-image: url('../../../assets/images/bg_001.jpg');
  background-blend-mode: lighten;
  background-size: cover;
  display: flex;
  align-items: center;
  .title {
    width: 50%;
    font-size: 2rem;
    text-align: center;
  }
  .details {
    width: 50%;
    line-height: 1.75;
  }
}

@media screen and (max-width: 800px) {
  #profile {
    background-size: cover;
    background-position-x: -35rem;
    flex-direction: column;
    .title {
      width: 100%;
      margin-top: 4rem;
      font-size: 1.5rem;
    }
    .details {
      width: 100%;
      margin-top: 2rem;
      font-size: 0.9rem;
    }
  }
}
