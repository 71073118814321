#pagination {
  position: fixed;
  top: 50%;
  right: 32px;
  transform: translateY(-50%);
}

#pagination a {
  display: block;
  width: 12px;
  height: 12px;
  margin: 24px 0;
  border-radius: 50%;
  background-color: rgba($color: #777777, $alpha: 0.5);
  transition: transform 0.2s;
}

#pagination a.active {
  transform: scale(1.8);
}