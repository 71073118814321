main {
  width: 100%;
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: auto;
  scrollbar-width: none;
}

main::-webkit-scrollbar {
  display: none;
}

section {
  width: 100%;
  height: 100vh;
  padding: 10%;
  scroll-snap-align: start;
}

.pagination a.active {
  transform: scale(1.8);
}

@media screen and (max-width: 800px) {
  section {
    padding: 5%;
  }
}