footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 2rem;
  padding-top: 0.4rem;
  text-align: center;
  color: rgb(255, 255, 255);
}