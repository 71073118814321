#portfolio {
  background-color: rgba(255, 255, 255, 0.75);
  background-image: url('../../../assets/images/bg_003.png');;
  background-blend-mode: lighten;
  background-size: cover;
  display: flex;
  align-items: center;
  .title {
    width: 50%;
    font-size: 2rem;
    text-align: center;
  }
  .details {
    width: 50%;
    max-height: 100%;
    line-height: 1.75;
    overflow-y: auto;
    h3 {
      font-size: 1.5rem;
      text-decoration: underline 0.125rem;
      text-underline-offset: 0.25rem;
    }
    p {
      margin: .5rem .5rem .75rem;
    }
  }
}

@media screen and (max-width: 800px) {
  #portfolio {
    background-image: url('../../../assets/images/bg_003_sp.png');;
    background-size: cover;
    flex-direction: column;
    .title {
      width: 100%;
      margin-top: 4rem;
      font-size: 1.5rem;
    }
    .details {
      width: 100%;
      margin-top: 2rem;
      font-size: 0.9rem;
      overflow-y: auto;
    }
  }
}