.top_image {
    width: 30rem;
    margin: 3rem auto 0;
    img {
        width: 100%;
        height: auto;
    }
}

.catch p {
    margin-top: 3rem;
    text-align: center;
    font-size: 2.5rem;
}

@media screen and (max-width: 800px) {
    .top_image {
        margin: 4rem auto 0;
        width: 18rem;
    }

    .catch p {
        margin-top: 4rem;
        font-size: 2rem;
    }
}